import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  active?: boolean
  category: string
  onClick?: (e: any) => void
  title: string
}

export const Filter = memo(function Filter({
  active = false,
  category,
  onClick,
  title,
}: Props) {
  if (!title) {
    return null
  }

  return (
    <Container
      className={active ? 'active' : undefined}
      data-category={category}
      onClick={onClick}
    >
      {title}
    </Container>
  )
})

const Container = styled.div`
  border: 0.0625rem solid ${({ theme }) => theme.colors.variants.neutralLight3};
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 600;
  line-height: 1.1875rem;
  margin: 0 0.6875rem;
  padding: 1.75rem 1.875rem 1.6875rem;
  transition: 0.2s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.variants.secondaryLight};
  }
  &.active {
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    border-color: transparent;
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  @media (max-width: 1199px) {
    padding: 1.375rem 1.5rem 1.3125rem;
  }

  @media (max-width: 1023px) {
    font-size: 0.875rem;
    margin: 1rem 0.25rem 0;
    padding: 1rem 1rem 0.875rem;
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 0.375rem 0 0;
  }
`
